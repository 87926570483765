<template>
  <!-- update code -->
  <v-card
    class="pa-0"
    style="margin-top: 20px; margin-left: 20px; margin-right: 20px"
  >
    <v-toolbar min-width="100%" dark color="primary">
      <v-card-title>My Students </v-card-title>
      <v-spacer> </v-spacer>
    </v-toolbar>
    <v-row
      style="
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
        border: 1px solid;
        margin-bottom: 10px;
      "
    >
      <v-col>
        <v-autocomplete
          v-model="selectedClassroom"
          :items="rooms"
          label="Filter by Classroom"
          
          item-text="name"
          item-value="id"
          style="font-size: large"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <DataTableSSR
      apiEndPoint="/room_user_payment/"
      :headers="headers"
      :instantLoad="true"
      :queryParams="{
        class_id: selectedClassroom,
      }"
    >
      <!-- <template #[`item.name`]="{ item }">
      {{ item.student_name}}
    </template>
    <template #[`item.amount`]="{ item }">
      {{ item.fee_amount}}
    </template> -->
      <template #[`item.date`]="{ item }">
        {{ moment(item.created_at).format("DD-MMM-YY") }}
      </template>
    </DataTableSSR>
  </v-card>
</template>
  
<script>
import DataTableSSR from "../../components/global/DataTableSSR.vue";

export default {
  components: { DataTableSSR },

  data: () => ({
    headers: [
      {
        text: "Full Name",
        value: "user_full_name",
      },
      {
        text: "Class",
        value: "name",
      },
      {
        text: "Course Amount",
        value: "payment",
      },
      {
        text: "Payment Date",
        value: "date",
      },
    ],
    studentData: [],
    rooms: [],
    selectedClassroom:"",
    userData: [],
  }),
  mounted() {
    this.loadData()

    this.loadUserFee()

  },
  computed: {
    
    // filteredUserData() {
    //   return this.userData.filter(
    //     (item, index, self) =>
    //       index === self.findIndex((t) => t.name === item.name)
    //   );
    // },
  },
  methods: {
    loadUserFee() {
      this.$api.get("/room_user_payment/").then((res) => {
        this.userData = res.data.results;
      });
    },

    loadData() {
     this.$api.get("/rooms/").then((response) => {
        this.rooms = response.data.results;
      });
    },
    filterByClassroom() {
      const query = { selectedClassroom: this.selectedClassroom };
      this.$router.push({ query });
    },
  },
};
</script>
  
<style></style>
