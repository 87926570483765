<template>
  <v-container>
    <slot name="title"></slot>
    <v-data-table :loading="loading" :headers="indexedHeaders" :items="extraData ? computedItems : items"
      :options.sync="options" :footer-props="{ 'items-per-page-options': items_per_page_options }"
      :server-items-length="pagination.count" :show-expand="showExpand" :expanded.sync="expanded" >
      <template #top>
        <v-row>
          <v-col cols="12" md="4">
            <slot name="table-top-left">
              <v-card-text class="text-subtitle-1 text-left">
                Total Count:
                <span class="font-weight-bold pa-0">{{ pagination.count || 0 }}
                </span></v-card-text>
            </slot>
          </v-col>
          <v-col cols="8" md="4">
            <slot name="table-top-mid">
              <slot v-if="searchable" name="search-input">
                <v-text-field v-model="search" solo type="search" :placeholder="searchPlaceholder" @input="onSearch">
                  <template #append>
                    <v-btn icon @click="clearSearch">
                      <v-icon small class="ma-0 pa-0" color="red lighten-2">mdi-close</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </slot>
            </slot>
          </v-col><v-col cols="4" md="4" class="text-center">
            <slot name="table-top-right">
              <v-btn v-if="showExport && items.length > 0" :loading="loading_export" color="primary" outlined
                @click="exportExcel()">
                <v-icon left> mdi-file-excel </v-icon> Export
              </v-btn>
            </slot>
          </v-col>
        </v-row>
      </template>
      <template #[`item.index`]="{ index }">
        {{ (pagination.current_page - 1) * options.itemsPerPage + index + 1 }}
      </template>
      <template v-for="(_, slot) in $scopedSlots" #[slot]="props">
        <slot :name="slot" v-bind="props" />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  props: {
    showExport: {
      type: Boolean,
      default: false
    },
    showExpand: {
      type: Boolean,
      default: false
    },
    exportURLEndpoint: {
      type: String,
      default: 'export/'
    },
    instantLoad: {
      // whether to load on instantiation
      type: Boolean,
      default: false
    },
    apiEndPoint: {
      type: String,
      required: true
    },
    queryParams: {
      type: Object,
      required: false,
      default: () => ({})
    },
    headers: {
      type: Array,
      required: false,
      default: () => []
    },
    ordering: {
      type: String,
      required: false,
      default: undefined
    },
    descendingOrder: {
      type: Boolean,
      required: false,
      default: false
    },
    itemsPerPageOptions: {
      type: Array,
      required: false,
      default: () => undefined
    },
    addExtraData: {
      type: Boolean,
      required: false,
      default: false
    },
    extraData: {
      type: Object,
      required: false,
      default: () => ({})
    },
    pageSize: {
      type: Number,
      required: false,
      default: 10
    },
    pageNumber: {
      type: Number,
      required: false,
      default: 1
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false
    },
    searchPlaceholder: {
      type: String,
      required: false,
      default: 'Search'
    }
  },
  data() {
    return {
      menu: false,
      date: '',
      loading: false,
      loading_export: false,
      pagination: {},
      items: [],
      expanded: [],
      search: '',
      options: {
        page: this.queryParams.page || 1,
        itemsPerPage: this.pageSize,
        sortBy: [this.ordering || this.headers[0].value],
        sortDesc: [this.descendingOrder || true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      },
      items_per_page_options: this.itemsPerPageOptions
        ? [...this.itemsPerPageOptions, this.pageSize, -1]
        : [10, 20, 50, 100, 200, 500, 1000, -1]
    }
  },
  computed: {
    computedItems() {
      return this.addExtraData
        ? this.items.map((item) => ({
          ...item,
          ...this.extraData
        }))
        : this.items
    },
    sorting() {
      const ordering = this.options.sortBy.map((e, i) => {
        if (this.options.sortDesc[i]) {
          return '-' + e
        } else {
          return e
        }
      })
      return ordering
    },
    indexedHeaders() {
      return [
        {
          text: '#',
          sortable: false,
          value: 'index'
        },
        ...this.headers
      ]
    },
    paginationQuery() {
      return { page: this.options.page, page_size: this.options.itemsPerPage }
    }
  },

  watch: {
    options: {
      handler(newVal, oldVal) {
        if (
          (oldVal !== {} && newVal.page !== oldVal.page) ||
          newVal.itemsPerPage !== oldVal.itemsPerPage ||
          newVal.sortBy !== oldVal.sortBy ||
          newVal.sortDesc !== oldVal.sortDesc
        ) {
          this.loadData()
        }
      },
      deep: true
    },
    apiEndPoint(newVal, oldVal) {
      if (newVal !== oldVal && newVal !== undefined) {
        if (this.instantLoad) {
          this.loadData()
        }
      }
    },
    queryParams(newVal, oldVal) {
      if (newVal !== oldVal && newVal !== undefined) {
        if (this.instantLoad) {
          this.loadData()
        }
      }
    }
  },

  async mounted() {
    if (this.instantLoad) {
      await this.loadData()
    }
  },
  methods: {
    apiWrapper(
      apiEndPoint = this.apiEndPoint,
      extraParams = {},
      forExport = false
    ) {
      const params = {
        ...this.queryParams,
        ...(!forExport ? this.paginationQuery : {}),
        search: this.search
      }
      if (this.sorting) {
        params.ordering = this.sorting.join(',')
      }

      return new Promise((resolve, reject) => {
        this.$axios
          .get(`${apiEndPoint}`, { ...extraParams, params: { ...params } })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    loadData() {
      this.loading = true
      return this.apiWrapper()
        .then((res) => {
          const { results, ...pagination } = res.data
          this.items = results
          this.pagination = pagination
          this.loading = false
        })
        .catch(() => (this.loading = false))
    },
    exportExcel() {
      this.loading_export = true
      return this.apiWrapper(
        this.apiEndPoint + this.exportURLEndpoint,
        {
          responseType: 'arraybuffer'
        },
        true
      )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `${this.$route.name}_${new Date().toISOString()}.xlsx`
          )
          document.body.appendChild(link)
          link.click()
          this.loading_export = false
        })
        .catch(() => {
          this.loading_export = false
          alert('Error exporting excel')
        })
    },
    onSearch() {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        this.loadData()
      }, 800)
    },
    clearSearch() {
      if (this.search !== '') {
        this.search = ''
        this.loadData()
      }
    }
  }
}
</script>

<style>

</style>